<template>
  <div class="home">

    <!-- "Banner" is the home picture with text -->
 <banner /> 
   <br/>
   <!-- <v-row>
     <v-card
      height="100"
      :to="{name:'About'}">
     </v-card>

   </v-row> -->
<v-row 
      class='pb-16'>
  <v-col
    v-for="(item,index) in cardItems"
    :key="index"
    sm="4">
    <v-card
      class="Card col-sm"
      height="305"
      :to="item.routerlink">
      <v-img
      :src="item.pic"
      height="200px">
      </v-img>
      <v-card-title>
        {{item.title}}
      </v-card-title>
      <v-card-subtitle  align="left">
        {{item.text}}
      </v-card-subtitle>
    </v-card>
  </v-col>

</v-row>

<Footer/>
  </div>

</template> 
<script>
import { mapGetters } from 'vuex'
// @ is an alias to /src

export default {
  components:{
    Banner: () => import('@/components/home/Banner'),
    Footer: () => import('@/components/core/Footer'), 
  },
  data: () => ({
    
      // {title: 'Extra',
      // routerlink: {name: 'FunFacts'},
      // text: 'Arbitrary things',
      // pic:require('@/assets/articles/SolarHistory.jpg')},
    
  }),
  computed: {
    ...mapGetters(['newestArticle']),
    NewestArticlepictureUrl() {
      let pics =  this.$store.getters.articleLinkedPictures(this.newestArticle.key)
      if (pics.length) {
        return pics[0].url
      } else {
        return ''
      }
    },

    cardItems() {
      let articleList = [
        {
          title: 'Blog',
          routerlink: {name: 'Blog'},
          text: 'Read all of our stories',
          pic:require('@/assets/articles/pyrenees.jpg')
        },
        {
          title: 'Map',
          routerlink: {name: 'Map'},
          text: 'See where we have been',
          pic:require('@/assets/articles/bird.jpg')
        }
      ]

      if (this.newestArticle) {
        let NewestArticle = 
          {
            title: 'Latest Article',
            routerlink: {name: 'BlogEntry', params:{blogID:this.newestArticle.key}},
            text: 'Read our latest published article',
            // pic:require('@/assets/articles/pyrenees.jpg')}
            pic:this.NewestArticlepictureUrl
          }
        articleList.unshift(NewestArticle) 
      }
      return articleList
    }
  },
  methods: {
    loadNewestArticle(){
      console.log('loading newest article...')
      let NewestArticle = 
      {title: 'Latest Article',
      routerlink: {name: 'BlogEntry', params:{blogID:this.newestArticle.key}},
      text: 'Read our latest published article',
      // pic:require('@/assets/articles/pyrenees.jpg')}
      pic:this.NewestArticlepictures[0].url}
      this.cardItems.unshift(NewestArticle)
    }      
  },
  mounted() {
    // this.loadNewestArticle()
  },
    
}
</script>

<style scoped>

</style>
